/*****************************************************************
   HERO STYLES
   - Base styles
*****************************************************************/
@use '../01-abstracts/mixins' as *;



// Base styles
// ------------------------------------------------------------
.c-hero {
   --nav-height: 130px;
   // @media (min-width: 640px) { --nav-height: 150px; }
   // @media (min-width: 960px) { --nav-height: 128px; }

   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: flex-start;
   width: 100%;
   background-color: var(--dkred);
   background-image: var(--bg-image); // set inline
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   border-bottom: 2px solid white;
   min-height: 210px;

   @media (min-width: 640px) { min-height: 280px; }
   @media (min-width: 1024px) { min-height: 330px; }
   

   // > .row {
   //    padding-top: var(--nav-height);
   //    padding-bottom: 1.5rem;
   //    width: 100%;
   // }
   
 
   &--about { background-image: url(/assets/img/bg-about.jpg); }
   &--contact { background-image: url(/assets/img/bg-contact.jpg); }
   &--faq { background-image: url(/assets/img/bg-faq.jpg); }
   &--map { background-image: url(/assets/img/bg-how-to-buy.jpg); }
   &--products { background-image: url(/assets/img/bg-products.jpg); }
   &--reps { background-image: url(/assets/img/bg-rep-dealer.jpg); }

   &--home {
      --nav-height: 100px;
      position: relative;

      justify-content: center;
      min-height: 320px;
      border-bottom: 0;
      overflow: hidden;
      @media (min-width: 960px) { --nav-height: 128px; }
      @media (min-width: 768px) { min-height: 550px; }
      @media (min-width: 1024px) { min-height: 780px; }
   }

   &__header {
      h1 {
         color: white;
         font-size: 2.5rem;
         line-height: 1.125;
         padding: 8rem 0 3rem;

         @include bp($lg) {
            font-size: 3rem;
         }
      }
   }

   &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, .5);
      z-index: 1;
   }

   &__video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
   }

   &__content {
      margin-top: var(--nav-height);
      padding: 2rem 1.25rem 3rem;
      color: #fff;
      z-index: 10;

      @include bp(640px) { padding-left: 5%; }

      h1 {
         color: inherit;
         margin-bottom: 1rem;
         line-height: 1;
         font-weight: 300;
         // same as largest (i.e. default)
         font-size: 4rem;
         @include bp($md) { font-size: 5rem; }
         @include bp($lg) { font-size: 5.75rem; }
         @include bp($xl) { font-size: 6.5rem; }

         strong {
            font-weight: 700;
         }

         // &.largest {
         //    font-size: 4rem;
         //    @include bp($md) { font-size: 5rem; }
         //    @include bp($lg) { font-size: 5.75rem; }
         //    @include bp($xl) { font-size: 6.5rem; }
         // }

         &.smaller {
            font-size: 3.5rem;
            @include bp($md) { font-size: 4.25rem; }
            @include bp($lg) { font-size: 5rem; }
            @include bp($xl) { font-size: 5.5rem; }
         }

         &.smallest {
            font-size: 2.5rem;
            @include bp($md) { font-size: 3.25rem; }
            @include bp($lg) { font-size: 4.25rem; }
            @include bp($xl) { font-size: 4.75rem; }
         }
      }
      //          lg  md  sm  base
      // largest  80  72
      // larger   64
      // large    48

      
      &-subheading {
         font-size: 1.25rem;
         font-weight: 300;
         font-family: var(--font-body);
         line-height: 1.5;
         
         @media (min-width: 768px) {
            font-size: 1.625rem;
            margin-bottom: 100px;
         }

         @include bp($lg) {
            font-size: 2rem;
         }
      }
   }

   &__search {
      --button-width: 45px;
      display: grid;
      grid-template-columns: 60% var(--button-width);
      margin-top: 1.5rem;

      @include bp($sm) {
         display: block;
      }

      @media (min-width: 768px) {
         display: grid;
         grid-template-columns: 40% min-content;
      }

      input {
         border: 0;
         font-family: var(--font-display);
         height: 100%;
         margin: 0;
         padding: 0.5rem;

         @include bp($sm) {
            margin-bottom: 0.5rem;
            width: 100%;

            &::placeholder {
               color: transparent;
            }
         }

         @media (min-width: 768px) { margin: 0; }
      }

      button {
         display: block;
         background-color: var(--red);
         background-image: url(/assets/img/icon-search-footer@2x.png);
         background-position: center;
         background-repeat: no-repeat;
         background-size: 25px 25px;
         height: 100%;
         width: var(--button-width);
         color: white;
         font-weight: 700;
         text-transform: uppercase;
         font-size: 0.875rem;
         line-height: 2.875;
         letter-spacing: 0.1rem;
         text-align: center;

         @include bp($sm) {
            background-image: none;
            display: block;
            width: auto;
         }
         
         @include bp($md) {
            background-image: none;
            width: 238px;
         }

         span {
            @media (max-width: 639px) {
               position: absolute;
               // Hide text visually
               border: 0;
               clip: rect(0, 0, 0, 0);
               height: 1px;
               margin: -1px;
               overflow: hidden;
               padding: 0;
               white-space: nowrap;
               width: 1px;
            }
         }
      }
   }
}