/*****************************************************************
   BREADCRUMB STYLES
*****************************************************************/
@use '../01-abstracts/mixins' as *;


.c-breadcrumb {
   background: var(--dkgray);
   color: var(--ltgray);
   font-family: var(--font-body);
   font-size: 0.875rem;
   font-weight: 700;
   line-height: 1.25;
   margin-bottom: 2rem;
   padding: 15px 0;

   @include bp($sm) {
      margin-bottom: 3rem;
   }

   @include bp($lg) {
      margin-bottom: 3.5rem;
   }
 
   a {
     color: var(--ltgray);
     font-weight: 400;
     margin: 0 5px;
     border: none;
 
     &:first-child {
       margin-left: 0;
     }
 
     &:hover {
       color: white;
       text-decoration: none;
       border: none;
     }
   }

   img {
      display: inline-block;
   }
 
   span {
     margin: 0 5px;
   }
 }