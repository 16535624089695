/*****************************************************************
   PAGE HEADERS STYLES
*****************************************************************/
@use '../01-abstracts/mixins' as *;

.c-page-intro {
   font-size: 1.25rem;
   margin-bottom: 3rem !important;

   @include bp($sm) {
      margin-bottom: 3.5rem !important;
   }

   @include bp($lg) {
      font-size: 1.5rem;
      margin-bottom: 4rem !important;
   }

   > * {
      font-size: inherit;
   }

   p:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
   }
}