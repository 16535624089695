/*****************************************************************
   BUTTON STYLES
   - Default <button> styles
   - Standard button
   - Icon button
*****************************************************************/
@use '../01-abstracts/mixins' as *;


// Default <button> styles
// ------------------------------------------------------------
button {
   background-color: transparent;
   border: 0;
   color: var(--dkgray);
   margin: 0;
   padding: 0;

   &:hover {
      cursor: pointer;
   }
}

// Standard button
// ------------------------------------------------------------
.button {
   background-color: var(--red);
   border: 0;
   color: white;
   display: inline-block;
   font-family: var(--font-display);
   font-size: 0.875rem;
   font-weight: 500;
   // text-transform: uppercase;
   padding: 0.5rem 1.5rem;

   &:hover {
      background-color: var(--dkred);
      border: 0;
      color: white;
      cursor: pointer;
   }
}

// Icon button
// ------------------------------------------------------------
.icon-button {
   background-color: transparent;
   border: 0;
   color: var(--red);
   font-weight: 400;
   margin: 0;
   padding: 0;
   display: grid;
   grid-template-columns: 20px auto;
   width: max-content;
   gap: 0.35rem;

   &.alternate {
      width: 100%;
   }

   > img {
      margin-top: 3px;
   }

   > * {
      align-self: flex-start;
   }
}