/*****************************************************************
   MAIN ELEMENT STYLES
*****************************************************************/
@use '../01-abstracts/mixins' as *;


main {
   // This is to make the footer sticky, in combo with body styles
   flex: 1 0 auto;
   min-height: auto;
   // padding-bottom: 3rem;

   // @include bp($sm) { padding-bottom: 4rem; }
   // @include bp($lg) { padding-bottom: 5rem; }
   // @include bp($xl) { padding-bottom: 7rem; }
}