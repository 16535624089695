/*****************************************************************
   ACCORDION STYLES
*****************************************************************/



.accordion { margin-bottom: rem-calc(30); }

.accordion-title {
  color: var(--red);
  padding: 0 0 20px !important;
  border: 0px !important;
  font-family: var(--font-body);
  font-size: rem-calc(16);
  line-height: 120%;
  font-weight: 700;

  &:focus,
  &:hover {
    background: none;
    color: var(--red);
  }

  &:visited {
    padding: 0 0 20px;
    color: var(--red);
  }

  img {
    margin-right: 5px;
    display: inline-block;
    vertical-align: baseline;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.4s ease-in;
    -moz-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
  }
}

.accordion-item.is-active .accordion-title {
  img {
    -webkit-transform: rotate(90deg) !important;
    -moz-transform: rotate(90deg) !important;
    -ms-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;
    -webkit-transition: all 0.4s ease-in;
    -moz-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
  }
}

.accordion-title:before { content: ''; }

.is-active>.accordion-title:before { content: ''; }

.accordion-content {
  border: 0px !important;
  padding: 0 0.5rem;
  color: var(--dkgray);

  p {
    color: var(--dkgray);
  }

  ul,
  ol {
    margin-bottom: 1.25rem;
    margin-left: 2rem;
  }
}