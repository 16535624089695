/*****************************************************************
   RELATED PRODUCTS COMPONENT STYLES
*****************************************************************/
@use '../01-abstracts/mixins' as *;

.c-related-products {
   padding-bottom: 4rem !important;

   @include bp($lg) {
      padding-bottom: 6rem !important;
   }

   &__heading {
      border-bottom: 3px solid var(--dkred);
      font-size: 0.875rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
      padding-bottom: 0.25rem;
   }

   .c-product-item__title {
      font-size: 1.125rem !important;
      margin-bottom: 0.5rem;
   }
}