/*****************************************************************
   PRODUCTS SECTION STYLES
   - Product listing container
   - Product listing sidebar
   - Product listing grid
   - Product listing list
   - Product listing view switcher
   - Product detail page
*****************************************************************/
@use '../01-abstracts/mixins' as *;


// Product listing
// ------------------------------------------------------------
.products-listing {
   display: grid;
   gap: 3rem;
   padding-bottom: 3rem;

   @include bp($sm) {
      grid-template-columns: 35% auto;
   }

   @include bp(800px) {
      grid-template-columns: 30% auto;
   }

   @include bp($lg) {
      grid-template-columns: 25% auto;
   }

   @include bp(1180px) {
      gap: 4rem;
      grid-template-columns: 20% auto;
   }
}

// Product listing idebar
// ------------------------------------------------------------
.products-listing__sidebar {
   &-heading {
      color: var(--gray);
      font-size: 0.875rem;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
   }
}

// Product listing grid
// ------------------------------------------------------------
.products-listing__products-grid {
   display: grid;
   gap: 3rem;

   @include bp(800px) {
      grid-template-columns: repeat(2, 1fr);
   }

   @include bp($lg) {
      grid-template-columns: repeat(3, 1fr);
   }
}

// Product listing list
// ------------------------------------------------------------
.products-listing__products-list {
   display: grid;
   gap: 1.5rem;

   @include bp($lg) {
      gap: 2rem;
   }
}

// Product listing view switcher
// ------------------------------------------------------------
.products-listing__view-switcher {
   display: grid;
   border-bottom: 1px solid var(--ltgray);
   gap: 0.25rem;
   grid-template-columns: auto 32px 32px;
   justify-content: flex-end;
   margin-bottom: 1.5rem;
   padding-bottom: 1.5rem;

   > * {
      align-self: center;
   }

   p {
      color: var(--dkgray);
      font-family: var(--font-display);
      font-size: 0.625rem;
      font-weight: 700;
      margin: 0 0.5rem 0 0;
      padding: 0;
      text-align: right;
      text-transform: uppercase;
   }

   a {
      border: 0;

      &:hover {
         border: 0;
      }
   }
}

// Product detail page
// ------------------------------------------------------------
.t-product-detail .product {
   margin-bottom: 6rem;

   @include bp($sm) {
      display: grid;
      grid-template-columns: 35% auto;
      gap: 2rem;
   }

   @include bp($lg) {
      grid-template-columns: 40% auto;
      margin-bottom: 10rem;
   }

   &__images {

      &-thumbnails {
         display: flex;
         flex-wrap: wrap;
         justify-content: center;
         align-items: center;
         // gap: 0 0.625rem 1rem;

         img {
            margin: 0 1rem 1rem;
         }
      }
   }

   &__info {

      &-title {
         color: var(--red);
         font-size: 2rem;
         line-height: 1.25;
         margin-bottom: 1.5rem;

         @include bp($lg) {
            font-size: 3rem;
         }
      }

      &-specs {
         color: var(--gray);
         font-family: var(--font-display);
         font-size: 1.125rem;
         font-weight: 300;
         margin-bottom: 2.5rem;
      }

      &-description {
         font-size: 1rem;
         margin-bottom: 2.5rem;
         line-height: 1.75rem;
         
         > p {
            font-weight: 400;
            line-height: inherit;
         }
      }

      &-subheading {
         font-family: var(--font-display);
         font-size: 1rem;
         line-height: 1;
         margin-bottom: 0.5rem;
         text-transform: uppercase;
      }

      &-features {
         display: grid;
         gap: 1.5rem;

         @include bp($lg) {
            gap: 2rem;
            grid-template-columns: repeat(2, 1fr);
         }
      }

      &-features-column:last-child {
         margin-bottom: 2rem;

         @include bp($lg) {
            margin: 0;
         }
      }

      &-features-column ul {
         margin-bottom: 2rem;
         @include reset-list;
         
         li {
            line-height: 1.5;
            margin-bottom: 0.5rem;
         }
      }

      &-resources {
         display: flex;
         flex-direction: column;
         gap: 0.625rem;

         .alternate {
            margin-top: 1.5rem;
         }
      }
   }
}