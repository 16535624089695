/*****************************************************************
   PAGINATION STYLES
*****************************************************************/



.c-pagination {
   text-align: center;
   padding: 40px 25px;
   font-family: var(--font-body);
   font-size: 0.875rem;
   line-height: 140%;
   text-transform: uppercase;
   margin-top: 3rem;
 
   .ends {
     color: var(--ltgray);
     margin: 0 15px;
     letter-spacing: 0.1rem;
 
     a {
       color: var(--red);
       padding: 0;
       border: 0;
 
       &:hover {
         color: var(--dkred);
         border: 0;
       }
 
     }
 
   }
 
   .link {
     margin: 0 5px;
     font-size: 1rem;
   }
 
   .current {
     color: white;
     background-color: var(--dkgray);
     padding: 5px;
     margin: 0 5px;
     font-size: 1rem;
     width: 27px;
     display: inline-block;
   }
 }