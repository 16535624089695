@use '../01-abstracts/mixins' as *;


.tabs {
   margin-top: 2rem;
   margin-inline: 0;
   padding-inline: 0;
   list-style: none;
   display: flex;
   flex-wrap: wrap;
   gap: 0.25rem;

   li {
      font-size: inherit;
      color: var(--ltgray);
      font-weight: inherit;
      background-color: var(--dkgray);
      padding: 0.5rem 0.75rem;
      text-transform: uppercase;
      font-weight: 700;
      @include transition(background-color);
      
      &:hover {
         background-color: var(--dkred);
      }

      a {
         color: inherit;
         border: 0;
   
         &:hover {
            border: 0;
         }
      }
   }

   li.uk-active {
      background-color: var(--red);

      a {
         color: white;
      }
   }
}

.tabs-container {
   min-height: 140px;

   @include bp($sm) { min-height: 420px; }
   @include bp($md) { min-height: 500px; }
   @include bp($lg) { min-height: 400px; }
   @include bp($xl) { min-height: 470px; }
   @include bp($xxl) { min-height: 530px; }
}