/*****************************************************************
   FAQ PAGE
   - Base layout styles
*****************************************************************/
@use '../01-abstracts/mixins' as *;


.l-faq .content {
   display: grid;
   gap: 3rem;
   padding-bottom: 3rem;

   @include bp($sm) {
      gap: 1.5rem;
      grid-template-columns: 35% auto;
   }

   @include bp(800px) {
      grid-template-columns: 30% auto;
   }

   @include bp($lg) {
      grid-template-columns: 33% auto;
   }

   &__faq {
      order: 1;

      @include bp($sm) {
         order: 2;
      }
   }
   
   &__sidebar {
      order: 2;

      @include bp($sm) {
         order: 1;
      }
   }
}

.l-faq .content__faq {

   &-category {
      color: var(--dkgray);
      font-size: 1.125rem;
      line-height: 1.2;
      margin-bottom: 1rem;
      text-transform: uppercase;
   }

   &-list {
      margin-bottom: 3rem !important;
      @include reset-list;

      &:last-of-type {
         margin-bottom: 5rem;
      }

      > li {
         font-size: 1rem;
         margin-bottom: 1.25rem !important;
      }

      > li > a {
         display: grid;
         border: 0;
         color: var(--red);
         font-size: inherit;
         font-weight: 700;
         gap: 0.5rem;
         grid-template-columns: 6px auto;
         line-height: 1.2;
         @include transition(color);

         &:hover {
            border: 0;
            color: var(--dkred);
         }

         img {
            margin-top: 5px;
         }
      }

      > li div.uk-accordion-content {
         font-size: 1rem;
         line-height: 1.5;
         padding: 0 0 0 calc(6px + 0.5rem);
         margin: 0;

         > * {
            font-size: inherit;
            line-height: inherit;
         }

         > ul {
            margin-left: 1rem;
         }
      }
   }

   &-info {
      font-size: 0.875rem;

      > * {
         font-size: inherit;
      }
   }
}