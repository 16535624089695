/*****************************************************************
   HOW TO BUY / MAP PAGE
*****************************************************************/
@use '../01-abstracts/mixins' as *;

.l-how-to-buy .c-breadcrumb {
   margin-bottom: 0;
}

.l-how-to-buy .intro {
   background-color: var(--ltrgray);
   margin: 0;
   padding: 2.5rem 0;

   .c-page-intro {
      margin-bottom: 0 !important;
      
      @include bp($sm) {
         max-width: 80%;
      }

      @include bp($lg) {
         max-width: 70%;
      }
   }

   p {
      font-size: inherit;
      line-height: inherit;
      
      &:first-child { margin-top: 0; }
      &:last-child { margin-bottom: 0; }
   }
}

.l-how-to-buy .maps {
   background-color: #191919;
   display: block;
   margin: 0;
   padding-block: 2rem;

   .tabs {
      margin-inline: auto;
      justify-content: center;
      text-align: center;
   }
}

.l-how-to-buy .info {
   display: grid;
   gap: 2rem;
   padding-top: 3rem;
   padding-bottom: 3rem;

   @include bp($sm) {
      gap: 2.5rem;
      grid-template-columns: repeat(2, 1fr);
   }

   h3 {
      font-size: 1.5rem;
      color: var(--burgundy);
      font-weight: 300;
      margin-bottom: 0.75rem;
   }

   p {
      line-height: 1.5;
   }
}