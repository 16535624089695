/*****************************************************************
   RESET STYLES
   Taken from the following sources:
   https://github.com/jensimmons/cssremedy/tree/master/css
   https://piccalil.li/blog/a-modern-css-reset
*****************************************************************/


// Use border-box by default, globally
*,
*::before,
*::after { box-sizing: border-box; }


// Consistent line spacing
// https://drafts.csswg.org/css-inline-3/#line-sizing-property
html { line-sizing: normal; }


// Remove the tiny space around the edge of the page
body { margin: 0; }


// Set core root defaults
html { scroll-behavior: smooth; }


// Set core body defaults
body {
   line-height: 1.5;
   min-height: 100vh;
   text-rendering: optimizeSpeed;
}


// A elements that don't have a class get default styles
a:not([class]) {
   text-decoration-skip-ink: auto;
}


// Make images easier to work with
img,
picture {
  max-width: 100%;
  display: block;
}

figure {
   margin: 0;
   padding: 0;
}


// Inherit fonts for inputs and buttons
input,
button,
textarea,
select {
  font: inherit;
}


// Overflow by default is bad */
pre { white-space: pre-wrap; }


// Embedded elements
img, 
svg, 
video, 
canvas, 
audio, 
iframe, 
embed, 
object {
  display: block; // Block display is usually what we want
  max-width: 100%; // Responsive by default
  vertical-align: middle; // Remove strange space-below when inline
}

// Maintain intrinsic aspect ratios when `max-width` is applied
// (iframe, embed, and object have no intrinsic ratio, set height explicitly)
img, 
svg, 
video, 
canvas {
  height: auto;
}

// There is no good reason elements default to 300px, 
//and audio files are unlikely to come with a width attribute
audio { width: 100%; }


// Old Browsers (because some people still use IE <barf>)
// Remove the border on images inside links in IE10 and earlier
img { border-style: none; }

// Hide the overflow in IE10 and earlier
svg { overflow: hidden; }

// Default block display on HTML5 elements
article,
aside, 
figcaption, 
figure, 
footer, 
header, 
hgroup, 
main, 
nav, 
section {
  display: block;
}

// IE10
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; // Add the correct box sizing in IE10
  padding: 0; // Remove the padding in IE10
}


// Remove all animations and transitions for people that prefer not to see them
@media (prefers-reduced-motion: reduce) {
   *,
   *::before,
   *::after {
     animation-duration: 0.01ms !important;
     animation-iteration-count: 1 !important;
     transition-duration: 0.01ms !important;
     scroll-behavior: auto !important;
   }
}