/*****************************************************************
   ABOUT PAGE
*****************************************************************/
@use '../01-abstracts/mixins' as *;

.l-content-page .about {
   display: grid;
   gap: 3rem;

   @include bp($sm) {
      gap: 1rem;
      grid-template-columns: 33% auto;
      margin-bottom: 2rem;
   }

   &__content {
      @include bp($sm) {
         order: 2;
      }

      h2 {
         margin-bottom: 1.5rem;

         @include bp($lg) {
            font-size: 2rem;
         }
      }

      h3 {
         color: var(--burgundy);
         font-weight: 400;
      }

      p + h3 {
         margin-top: 2rem;
      }

      p {
         line-height: 1.5;
      }
   }

   &__aside {
      @include bp($sm) {
         order: 1;
      }

      .c-sidebar-links {
         max-width: 270px;
         width: 83%;
      }
   }
}