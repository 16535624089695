/*****************************************************************
   FOOTER STYLES
*****************************************************************/
@use '../01-abstracts/mixins' as *;


.c-footer {
   background-image: url(/assets/img/bg-footer-highway.jpg);
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   color: white;
   margin: 0;
   padding: 0;
}

.c-footer__linkset {
   background-color: var(--dkred);
   font-size: rem-calc(18);
   line-height: 3;
   padding: 1.5rem;

   @include bp($sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
   }

   @include bp($md) {
      display: grid;
      grid-template-columns: repeat(3, auto);
   }

   &-item {
      margin: 0 auto;
      width: max-content;

      @include bp($sm) {
         margin: 0 2rem;
      }
   
      a {
         display: grid;
         border: 0;
         color: inherit;
         font-family: var(--font-display);
         font-size: 1.125rem;
         font-weight: 700;
         gap: 0.625rem;
         grid-template-columns: 24px auto;
         opacity: 1;
         text-transform: uppercase;
         @include transition(opacity);

         &:hover {
            cursor: pointer;
            opacity: 0.7;
         }
      }

      a > * {
         align-self: center;
      }
   }
}

.c-footer__utility {
   padding: 3rem 1.5rem;
   text-align: center;
   width: 100%;

   &-tagline {
      display: inline-block;
      background-color: var(--red);
      color: inherit;
      font-family: var(--font-display);
      font-weight: 300;
      margin: 0 auto 3rem;
      padding: 0.25rem 0.75rem;
      text-transform: uppercase;

      strong {
         font-weight: 700;
      }
   }

   &-credits {
      font-family: var(--font-display);
      font-size: 0.75rem;

      p {
         color: var(--ltgray);
         font-size: inherit;
         margin: 0;
         padding: 0;
      }

      p:first-child {
         margin-bottom: 1rem;
      }

      p:last-child a {
         color: var(--dkgray);
         @include transition(color);

         &:hover {
            border: 0;
            color: var(--ltgray);
         }
      }
   }
}
