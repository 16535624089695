/*****************************************************************
   RESOURCES SECTION
   - Main page
   - Resources center / marketing & media center
*****************************************************************/
@use '../01-abstracts/mixins' as *;

// Main page
// ------------------------------------------------------------
.l-resources .credit-app {
   margin-bottom: 3rem;
   text-align: center;

   @include bp($sm) {
      margin-bottom: 4rem;
   }

   @include bp($md) {
      margin-bottom: 5rem;
   }

   @include bp($lg) {
      margin-bottom: 6rem;
   }
}

.l-resources .resources {
   display: grid;
   gap: 3rem;
   margin-bottom: 2rem;

   @include bp($sm) {
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
   }

   @include bp($md) {
      gap: 3rem;
   }

   @include bp($md) {
      gap: 5rem;
   }
}

// Resources center / marketing & media center
// ------------------------------------------------------------
.l-resources-center .resources {
   display: grid;
   gap: 3rem;
   margin-bottom: 2rem;

   @include bp($sm) {
      gap: 4rem;
      grid-template-columns: 25% auto;
   }

   &__info {
      @include bp($sm) {
         order: 2;
      }

      &-heading {
         margin-bottom: 1rem;
      }
   }

   &__aside {
      @include bp($sm) {
         order: 1;
         max-width: 256px;
      }
   }

   &__downloads {
      @include bp($lg) {
         columns: 2;
         break-inside: avoid;
      }

      &-item {
         margin: 0 0 1rem;
      }

      &-item a {
         border: 0;
         display: grid;
         gap: 0.5rem;
         grid-template-columns: 18px auto;

         &:hover {
            border: 0;
         }
      }
   }
}