/*****************************************************************
   CUSTOM LINKS
*****************************************************************/
@use '../01-abstracts/mixins' as *;

.c-sidebar-links {
   @include reset-list;

   li {
      font-family: var(--font-display);
      font-size: 0.875rem;
      font-weight: 700;
      margin-bottom: 0.625rem;
      text-transform: uppercase;
   }
   
   li a {
      display: block;
      background-color: var(--red);
      color: white;
      padding: 1rem 1.5rem 0.875rem;
      @include transition(background-color);

      &:hover {
         background-color: var(--dkred);
         color: white;
      }

      &.active {
         background-color: var(--dkred);
         color: white;
      }
   }

   li.active a {
      background-color: var(--dkred);
   }
}