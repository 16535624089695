/*****************************************************************
   SIDEBAR STYLES
*****************************************************************/



.sidebar {
   h2 {
     font-size: 1.5rem;
     margin-bottom: 0.625rem;
 
   //   @include breakpoint(medium down) {
   //     font-size: rem-calc(22);
   //   }
   }
 
   h5 {
     margin-bottom: 1.5rem
   }
 
   p {
     margin-bottom: 0;
   }
 
   ul {
     list-style: none;
     padding: 0;
     margin: 0;
 
     li {
       display: block;
       margin: 0 0 10px;
       font-family: var(--font-display);
 
       a {
         background: var(--red);
         font-size: 0.875rem;
         text-transform: uppercase;
         font-weight: 700;
         color: white;
         padding: 15px 25px 14px;
         display: block;
 
         &:hover {
           background: var(--dkred);
           color: white;
         }
 
         &.active {
           background: var(--dkred);
           color: white;
         }
       }
 
       &.active a {
         background: var(--dkred);
       }
     }
   }
 }