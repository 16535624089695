/*****************************************************************
   LINK STYLES
*****************************************************************/
@use '../01-abstracts/mixins' as *;

a {
  color: var(--red);
  text-decoration: none;
  border-bottom: 1px solid transparent;
//   padding-bottom: rem-calc(1);
  @include transition;

  &:hover {
    color: var(--dkred);
    border-bottom: 1px solid var(--dkred);
  }

  &.active {
    color: var(--dkred);
  }

//   &.under {
//     border-bottom: 1px solid var(--dkred);
//   }

//   &.btn,
//   &.button {
//     background: var(--red);
//     color: white;
//     padding: rem-calc(11) rem-calc(30);
//     font-family: var(--font-display);
//     font-size: rem-calc(14);
//     font-weight: var(--bold);

//     &:hover {
//       background: var(--dkred);
//     }

//   }
}