/*****************************************************************
   VARIABLES
   - Fonts
   - Colors
*****************************************************************/

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

:root {
   // Fonts
   // ------------------------------------------------------------
   --font-display: "Helvetica Neue", Helvetica, Arial, sans-serif;
   --font-body: "Open Sans", Helvetica, sans-serif;
   
   
   // Colors
   // ------------------------------------------------------------
   --dkgray: #414142;
   --gray: #6A6B6D;
   --ltgray: #D4D6D8;
   --ltrgray: #EAEBEC;
   
   --dkred: #781214;
   --red: #9C1C1F;
   --burgundy: #541111;
   --black: #1F1F21;
   --offwhite: #F8F8F8;
   --blue: #1980A3;
   --orange: #E6661F;
}