/*****************************************************************
   PRODUCT COMPONENT STYLES
   - Base styles
   - Card layout
   - List item layout
*****************************************************************/
@use '../01-abstracts/mixins' as *;


// Base styles
// ------------------------------------------------------------
.c-product-item {
   a {
      border: 0;
      
      &:hover {
         border: 0;
         text-decoration: none;
      }
   }

   figure {
      img {
         width: 100%;
      }

      figcaption {
         font-family: var(--font-display);
         text-align: center;
      }

      .flag {
         display: inline-block;
         background-color: var(--red);
         color: var(--offwhite);
         font-family: inherit;
         font-size: 0.875rem;
         font-weight: 700;
         margin: 0 auto 10px;
         padding: 6px 10px;
         text-transform: uppercase;
      }

      .flag.featured {
         background-color: var(--orange);
      }
   }

   &__title {
      color: var(--red);
      font-weight: 700;
   }

   &__specs {
      color: var(--gray);
      font-family: var(--font-display);
      font-size: 0.875rem;
      font-weight: 300;
      margin: 0;
   }
}

// Card layout
// ------------------------------------------------------------
[class$="products-grid"] .c-product-item {

   &__title {
      font-size: 1.125rem;
      margin-bottom: 0.625rem;
   }

   // &__specs {}
}

// List item layout
// ------------------------------------------------------------
[class$="products-list"] .c-product-item {
   a {
      border-bottom: 1px solid var(--ltgray);
      // padding-bottom: 1.5rem;
      display: grid;
      gap: 2rem;
      grid-template-columns: 33% auto;

      @include bp($sm) {
         grid-template-columns: 25% auto;
      }

      @include bp($lg) {
         grid-template-columns: 18% auto;
         padding-top: 2rem;
      }
   }

   &__title {
      font-size: 1rem;
      margin-bottom: 1.25rem;

      @include bp($lg) {
         font-size: 1.5rem;
      }
   }

   // &__specs {}
}