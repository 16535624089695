/*****************************************************************
   MIXINS
   - Breakpoints
   - Reset lists
   - Z-index
   - Fluid Aspect Ratio
   - Transition
   - Vertical align
*****************************************************************/


// Breakpoints
// ------------------------------------------------------------
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1440px;

// Breakpoint mixin
@mixin bp($min-width) {
   @media only screen and (min-width: $min-width) { @content; }
}



// Reset lists
// ------------------------------------------------------------
@mixin reset-list {
   list-style: none;
   margin: 0;
   padding: 0;
}



// Z-index
// ------------------------------------------------------------
// Usage: @include z-index(navigation);
$z-index: (
   behind: -10,
   simple: 1,
   nav: 10,
   search: 20,
   mobile-nav: 50,
   modal: 200,
);

@function z-index($key) {
   @return map-get($z-index, $key);
}

@mixin z-index($key) {
   z-index: z-index($key);
}



// Fluid Aspect Ratio
// ------------------------------------------------------------
// Usage: @include fluid-aspect(4 3, iframe);
@mixin fluid-aspect($ratio: 1 1, $selector: "> :first-child") {
   $selector: unquote($selector);

   padding-top: percentage(nth($ratio, 2) / nth($ratio, 1));
   position: relative;

   #{$selector} {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
   }
}




// Transition
// ------------------------------------------------------------
// Usage: @include transition([property],[duration],[easing]);
@mixin transition($property: all, $duration: 0.25s, $ease: ease-in-out){
	transition: $property $duration $ease;
}



// Vertical align
// ------------------------------------------------------------
@mixin vertical-align($position: relative) {
   position: $position;
   top: 50%;
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
}