/*****************************************************************
   VIDEO EMBED STYLES
*****************************************************************/



.c-video-embed {
   position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
   margin-top: 2rem;

   iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
   }
}