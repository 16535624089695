/*****************************************************************
   MODAL STYLES
   - Reveal (Foundation)
   - Micromodal
   - Micromodal animations
   *****************************************************************/
   @use '../01-abstracts/mixins' as *;

// Reveal (Foundation)
// ------------------------------------------------------------
.reveal {
   outline: none !important;
   border: 0px;
   padding: 4rem 2rem;

   button {
      position: absolute;
      top: 0px;
      right: 10px;
      color: var(--dkred);
      cursor: pointer;
      font-weight: 700;
      font-size: rem-calc(45);
      -moz-transition: all 0.2s;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;

      &:hover {
         color: var(--red);
      }
   }

   form {
      font-family: var(--font-display);

      input {
         width: 50%;
         background: white;
         height: 46px;
         border: 1px solid var(--red);
         outline: none;
         -webkit-appearance: none;
         display: inline;
         margin: 0;
         padding: 0 15px;
         vertical-align: middle;
         color: var(--gray);
         box-shadow: none;
         transition: none;

         &:focus {
            box-shadow: none;
            transition: none;
            border: 1px solid var(--red);
         }
      }

      input[type="submit"] {
         background: var(--red);
         text-align: center;
         color: white;
         text-transform: uppercase;
         font-size: rem-calc(14);
         line-height: 46px;
         letter-spacing: 0.1rem;
         font-weight: 700;
         padding: 0;
         -moz-transition: all 0.2s;
         -webkit-transition: all 0.2s;
         -o-transition: all 0.2s;
         transition: all 0.2s;

         &:hover {
            background: var(--dkred);
         }
      }

      // @include breakpoint(medium down) {
      //    input {
      //       width: 100%;
      //       display: block;
      //       margin-bottom: rem-calc(25);

      //       &:last-child {
      //          margin-bottom: 0;
      //       }
      //    }
      // }

      .float-label {
         label {
            left: 16px;
         }

         &.on,
         &.show {
            input[type="text"] {
               padding-top: 13px;
            }
         }
      }
   }

   #ajaxForm {
      padding-top: 20px;

      input[type="submit"] {
         width: 100%;
      }
   }

   h2 {
      font-size: 2rem !important;
      color: var(--red) !important;
   }

   p {
      font-size: 1.25rem;
   }
}

// Micromodal
// ------------------------------------------------------------
.modal {
   display: none;
   position: relative;
   z-index: 100;

   &.is-open {
      display: block;
   }

   // Overlay --------
   &__overlay {
      align-items: center;
      background: rgba(0, 0, 0, 0.7);
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
   }

   // Container --------
   &__container {
      background-color: #fff;
      border-radius: 4px;
      max-height: 90vh;
      overflow-y: auto;
      padding: 30px;
      position: relative;
      width: 90vw;

      @media (min-width: 900px) {
         max-width: 800px;
      }
   }

   // Header --------
   &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
   }

   // Title --------
   &__title {
      box-sizing: border-box;
      color: #00449e;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.25;
      margin-bottom: 0;
      margin-top: 0;
   }

   // Close button --------
   &__close {
      position: absolute;
      right: 0.5rem;
      top: -0.5rem;
      font-size: 2rem;
   }

   // Content --------
   &__content {
      color: rgba(0, 0, 0, 0.8);
      line-height: 1.5;
      margin-bottom: 2rem;
      margin-top: 2rem;
   }
}

//  Micromodal animations
// ------------------------------------------------------------
@keyframes mmfadeIn {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}

@keyframes mmfadeOut {
   from {
      opacity: 1;
   }
   to {
      opacity: 0;
   }
}

@keyframes mmslideIn {
   from {
      transform: translateY(15%);
   }
   to {
      transform: translateY(0);
   }
}

@keyframes mmslideOut {
   from {
      transform: translateY(0);
   }
   to {
      transform: translateY(-10%);
   }
}

.micromodal-slide {
   display: none;

   &.is-open {
      display: block;
   }
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
   animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
   animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
   animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
   animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
   will-change: transform;
}