/*****************************************************************
   NAV STYLES
   - Nav container
   - Logo
   - Mobile button
   - Menu/links
   - Styles for when mobile nav is open
*****************************************************************/
@use '../01-abstracts/mixins' as *;
$nav-bp: 1020px;
$nav-bp-lg: 1080px;



// Nav container
// ------------------------------------------------------------
.nav {
   --h-pad: 20px;
   display: flex;
   position: fixed;
   left: 50%;

   // max-width: 1023px; // <- TEMPORARY
   align-items: center;
   background-color: transparent;
   flex-wrap: wrap;
   font-family: var(--font-display);
   justify-content: space-between;
   margin: 0 auto;
   padding: 1rem var(--h-pad);
   transform: translateX(-50%);
   transition: background-color 0.3s ease-in-out;
   width: 100%;
   z-index: 150;
   @include bp($lg) { --h-pad: 60px; }

   @media (min-width: $nav-bp) {
      padding: 2rem var(--h-pad);
      // max-width: calc(1440px + var(--h-pad) );
   }

   &.red {
      background-color: rgba(#9C1C1F, 0.9);
   }
}



// Logo
// ------------------------------------------------------------
.nav__logo {
   display: block;
   border: 0;
   flex-shrink: 0;

   &:hover { border: 0; }

   img {
      width: 150px;

      @media (min-width: $nav-bp-lg) {
         width: 200px;
      }
   }
}



// Mobile button
// ------------------------------------------------------------
.nav__button {
   flex-shrink: 0;
   width: 32px;
   height: 32px;
   cursor: pointer;

   @media (min-width: $nav-bp) {
      display: none;
   }


   // Hamburger
   &--hamburger {
      background-image: url(/assets/img/icon-hamburger-white.svg);
      background-size: 32px 32px;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 20;
   }
   
   // Close (times)
   &--close {

      position: absolute;
      top: 1rem;
      right: 1rem;
      background-image: url(/assets/img/icon-close-red.svg);
      background-size: 32px 32px;
      background-position: center;
      background-repeat: no-repeat;
   }
}



// Menu/links
// ------------------------------------------------------------
.nav__menu {
   position: absolute;
   top: 0;
   right: 0;
   background-color: var(--ltgray);
   height: 100vh;
   max-width: 250px;
   padding: 2rem 1.5rem 0;
   transform: translateX(250px);
   width: 100%;
   @include transition(transform 0.5s);

   @media (max-width: $nav-bp) {
      @include z-index(mobile-nav);
   }

   @media (min-width: $nav-bp) {
      position: static;
      background-color: transparent;
      height: auto;
      max-width: none;
      padding: 0;
      transform: none;
      width: auto;
   }

   ul {
      color: var(--red);
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0 0 3rem;
      padding-top: 2rem;
      text-transform: uppercase;
      @include reset-list;
      
      
      @media (min-width: $nav-bp) {
         display: flex;
         color: #fff;
         justify-content: flex-end;
         margin: 0 54px 0 0;
         text-align: right;
         text-transform: none;
         padding: 0;
      }
   }

   ul li {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      margin-bottom: 1rem;
      
      @media (min-width: $nav-bp) {
         margin: 0 0 0 2rem;
      }
      
      &:first-child {
         @media (min-width: $nav-bp) { margin: 0; }
      }
   }
   
   ul li a {
      border-bottom: 2px solid transparent;
      color: inherit;
      opacity: 1;
      
      @media (min-width: $nav-bp) {
         text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
      }

      &:hover {
         color: inherit;
         opacity: 0.7;
         border-bottom: 2px solid rgba(255, 255, 255, 0.7);
      }

      &.active {
         color: #fff;
         border-bottom: 2px solid #fff;
      }
   }


   // Search form in off-canvas menu
   &-search {

      @media (min-width: $nav-bp) {
         display: none;
      }

      .float-label { display: flex; }

      input[type="search"] {
         // height: 40px;
         border: 1px solid var(--red);
         font-size: 0.875rem;
         margin: 0;
         padding: 0.25rem 0.5rem;
      }

      button {
         background-color: var(--red);
         background-image: url(/assets/img/icon-search-white.svg);
         background-position: center;
         background-repeat: no-repeat;
         background-size: 24px 24px;
         height: 40px;
         overflow: hidden;
         text-indent: 150%;
         white-space: nowrap;
         width: 48px;
      }
   }

   // Search expand
   .expand {
      display: none;

      @media (min-width: $nav-bp) {
         display: block;
         position: absolute;
         top: 50%;
         right: 20px;
         overflow: hidden;
         transform: translateY(-50%);
         width: 0%;
         min-width: 34px;
         height: 34px;
         transition: width 0.3s;

         &.exp-open {
            width: 100%;
         }

         .exp-input {
            position: absolute;
            top: 0;
            right: 0;
            width: 30%;
            height: 34px;
            z-index: 10;
            border-radius: 3px !important;
            border: 0;
         }

         .exp-submit {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #fff;
            opacity: 0;
            color: var(--red);
            border: none;
            outline: none;
            z-index: -1;
            width: 34px;
            height: 34px;
            padding: 0;
            margin: 0;
            text-align: center;
            cursor: pointer;
         }

         .exp-icon {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 90;
            width: 34px;
            height: 34px;
            padding: 0;
            margin: 0;
            text-align: center;
            cursor: pointer;
         }
      }
   }
}



// Styles for when mobile nav is open
// ------------------------------------------------------------
.nav.is-open {
   z-index: 100;

   .nav__menu {
      transform: translateX(0);
   }
}

body.has-overlay {
   position: relative;

   &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(#000, .7);

      // Remove the overlay in case of screen resize
      @media (min-width: $nav-bp) {
         display: none;
      }
   }
}