/*****************************************************************
   HOME PAGE STYLES
   - Intro
   - Touts
*****************************************************************/
@use '../01-abstracts/mixins' as *;

// Intro
// ------------------------------------------------------------
.l-home .intro {

   --intro-width: calc(100% - 3rem);
   background-color: var(--ltrgray);
   padding: 2rem 0 0;
   @include bp($md) {
      --intro-width: calc(100% - 5rem);
   }

   &__text {
      color: var(--dkgray);
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.5;
      margin: 0 auto 2rem;
      max-width: 1200px;
      width: var(--intro-width);
      
      @include bp($md) {
         margin: 0 auto 4rem;
         width: var(--intro-width);
      }
      
      p {
         color: inherit;
         font-size: inherit;
         font-weight: inherit;
         line-height: inherit;

         @include bp($lg) {
            font-size: 1.75rem;
         }
      }
   }

   &__products {
      display: grid;
      gap: 3rem;
      margin: 0 auto;
      max-width: 1200px;
      padding-bottom: 3rem;
      width: var(--intro-width);
   
      @include bp($sm) {
         gap: 2rem;
         grid-template-columns: repeat(3, 1fr);
      }
   
      @include bp($lg) {
         gap: 4rem;
      }
   }
}

// Touts
// ------------------------------------------------------------
.l-home .touts-grid {
   display: grid;

   @include bp($lg) {
      grid-template-columns: 40% auto;
   }
}

.l-home .touts-grid__mission {
   --mission-bg: url(/assets/img/bg-red-highway.jpg);
   display: flex;

   background-image: var(--mission-bg);
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   color: white;
   justify-content: center;
   align-items: center;
   padding: 6rem 4rem;

   @include bp($sm) {
      padding: 6rem;
   }

   @include bp($md) {
      padding: 6rem 10rem;
   }

   @include bp($lg) {
      --mission-bg: url(/assets/img/bg-red-highway@2x.jpg);

      padding: 6rem 5rem;
   }

   @include bp($xl) {
      padding: 6rem 8rem;
   }

   h2 {
      color: inherit;
      font-size: 1.75rem;
   }

   p {
      color: inherit;
      font-weight: 300;
      line-height: 1.75;
   }

   a {
      color: inherit;
      @include transition(border);

      &:hover {
         border-bottom: 1px solid white;
      }
   }
}

.l-home .touts-grid__map {
   --map-bg: url(/assets/img/bg-map.jpg);
   display: flex;
   align-items: center;
   background-image: var(--map-bg);
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   color: white;
   flex-direction: column;
   justify-content: center;
   margin: 0;
   padding: 3rem 2rem 4rem;

   @include bp($lg) {
      --map-bg: url(/assets/img/bg-map@2x.jpg);
   }

   &-inner {
      width: 100%;
   }

   &-header {
      
      h2, h3, h4 {
         color: white;
         text-align: center;

         @include bp($lg) {
            text-align: left;
         }
      }

      h2 {
         font-size: 1.5rem;

         @include bp($sm) {
            font-size: 1.75rem;
         }
      }

      h3 {
         font-size: 1.25rem;

         @include bp($sm) {
            font-size: 1.5rem;
         }
      }

      h4 {
         font-size: 1.1rem;

         @include bp($sm) {
            font-size: 1.25rem;
         }
      }
   }

   .state-selector-container {
      color: #fff;
      
      @include bp($lg) {
         margin: 0 0 0 -1.25rem;
         text-align: left;
         padding: 0;
      }
   
      label {
         display: block;
         color: #fff;
         font-weight: 700;
         margin-bottom: 0.5rem;
      }

      select {
         display: block;
         margin: 0 auto;
         max-width: 400px;

         @include bp($lg) {
            margin: 0;
         }
   
         @include bp(1240px) { display: none; }
      }
   }

   .c-map {
      background-color: transparent;
   }

   #svgmap {
      display: none;

      @media (min-width: 640px) {
         display: block;
         margin-top: -4rem;
      }
   }
}
