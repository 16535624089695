/*****************************************************************
   CONTAINER STYLES
*****************************************************************/
@use '../01-abstracts/mixins' as *;


.l-container {
   margin: 0 auto;
   padding: 0 1.25rem;
   width: 100%;
   max-width: $sm;

   @include bp($md) {
      max-width: $xxl;
   }

   &--xl {
      max-width: 90%;
   }

   &--full {
      max-width: 100%;
   }
}