/*****************************************************************
   BODY STYLES
*****************************************************************/
@use '../01-abstracts/mixins' as *;


// body {
//    // background: $ltgray;
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
//    color: $dkgray;
//    font-family: $open;
//    font-weight: $regular;
//    font-size: rem-calc(16);
//    line-height: 175%;
//    position: relative;
//  }


body {
   display: flex;
   color: var(--dkgray);
   flex-direction: column;
   font-family: var(--font-body);
   font-size: 1rem;
   height: 100%;
   text-rendering: optimizeLegibility;
}