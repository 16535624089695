/*****************************************************************
   MAP STYLES
*****************************************************************/
@use '../01-abstracts/mixins' as *;

.c-map {
   --z-layer: 2;

   background-color: #191919;
   color: #fff;
   padding-bottom: 2rem !important;
   padding-top: 2rem !important;
   text-align: center;
   overflow-y: hidden;

   label {
      display: block;
      width: 100%;
      max-width: 500px;
      margin-inline: auto;
      padding-inline: 1.5rem;
      position: relative;
      z-index: var(--z-layer);
      color: white;
   }

   label select {
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
   }

   .map-label {
      display: none;
      position: relative;
      z-index: var(--z-layer);
      font-size: 0.875rem;

      @include bp($sm) {
         display: block;
      }
   }

   svg {
      display: none;
      margin-inline: auto;
      
      @include bp($sm) {
         display: block;
      }

      &.us-canada {
         @include bp($sm) { margin-top: -4rem; }
         @include bp($md) { margin-top: -6rem; }
      }
   }
}

[data-micromodal-trigger] {
   @include transition(all);

   &:hover {
      cursor: pointer;
      // opacity: 0.5;
      fill: var(--red);
   }
}