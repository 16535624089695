/*****************************************************************
   ALERTS (custom, not Foundation)
*****************************************************************/



.alerts {
   position: fixed;
   right: 20px;
   bottom: 20px;
   z-index: 999;
 
   &__pill {
     display: flex;
     background-color: rgba(#9C1C1F, 0.82);
     border-radius: 94px;
     font-size: 14px;
     gap: 0.5rem;
     line-height: 1.2rem;
     margin-top: 10px;
     padding: 4px 24px 4px 16px;
 
     a {
       display: inline;
       color: white;
 
       &:hover {
         color: white;
         text-decoration: underline;
       }
     }
   }
 }