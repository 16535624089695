/*****************************************************************
   TYPOGRAPHY
   - Headings
   - Content font sizing
   - Custom UL
*****************************************************************/
@use "../01-abstracts/mixins" as *;

// Headings
// ------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
   color: var(--red);
   font-family: var(--font-display);
   font-weight: 700;
   line-height: 1.25;
   margin: 0;
   padding: 0;
}

h2 {
   font-size: 1.75rem;
}

h3 {
   font-size: 1.5rem;
}

h4 {
   font-size: 1.25rem;
}


// Content font sizing
// ------------------------------------------------------------
p,
ul,
ol,
figcaption {
   font-size: 1rem;
   font-weight: 300;
   line-height: 1.25;

   // @include bp($sm) {
   //    font-size: 1.25rem;
   // }
}

// Custom UL
// ------------------------------------------------------------
ul.arrow-list,
.arrow-list-wrap ul,
.uk-accordion-content ul {
   @include reset-list;
   
   li {
      line-height: 1.75;
      margin-bottom: 0.5rem;
      padding-left: 22px;
      position: relative;
   }

   li::before {
      // min-height: 28px;
      background-image: url(/assets/img/icon-bullet@2x.png);
      background-repeat: no-repeat;
      background-size: 13px 13px;
      content: "";
      margin: 5px 0 0 -22px;
      position: absolute;
      width: 13px;
      height: 13px;
   }
}