/*****************************************************************
   CONTACT PAGE
*****************************************************************/
@use '../01-abstracts/mixins' as *;

.l-contact-page .contact {
   display: grid;
   gap: 3rem;
   margin-bottom: 2rem;

   @include bp($sm) {
      gap: 2rem;
      grid-template-columns: 55% auto;
      margin-bottom: 3rem;
   }
   
   @include bp($md) {
      gap: 2.5rem;
      grid-template-columns: 60% auto;
      margin-bottom: 4rem;
   }
   
   @include bp($lg) {
      gap: 4rem;
      grid-template-columns: 65% auto;
      margin-bottom: 5rem;
   }

   &__info {
      order: 2;

      &-heading {
         font-size: 1.375rem;
         margin-bottom: 0.5rem;

         @include bp($lg) {
            font-size: 1.5rem;
            margin-bottom: 1rem;
         }
      }

      address {
         color: var(--dkgray);
         font-size: 0.875rem;
         font-style: normal;

         @include bp($lg) {
            font-size: 1rem;
         }

         > * {
            font-size: inherit;

            strong {
               font-weight: 700;
            }
         }

         .heading {
            color: inherit;
            margin-bottom: 0;
         }
         
         .tel {
            display: grid;
            grid-template-columns: 16% auto;
            margin: 0 0 0.75rem;
            padding: 0;

            @include bp($md) {
               grid-template-columns: 20% auto;
            }

            @include bp($lg) {
               margin-bottom: 1rem;
            }
         }
         
         .email {
            margin: 0 0 0.75rem;

            @include bp($md) {
               display: grid;
               grid-template-columns: 20% auto;
            }

            @include bp($lg) {
               margin-bottom: 1rem;
            }
            
            strong {
               display: block;

               @include bp($md) {
                  display: inline;
               }
            }
         }

         .address {
            margin: 0 0 0.75rem;
            padding: 0;
            line-height: 1.5;

            @include bp($lg) {
               margin-bottom: 1rem;
            }
         }
      }
   }

   &__form {
      order: 1;
      border-bottom: 1px solid var(--ltgray);
      padding-bottom: 3rem;

      @include bp($sm) {
         padding-bottom: 0;
         border-bottom: 0;
      }
   }
}