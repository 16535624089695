/*****************************************************************
   FORM STYLES
   - Base styles
   - Search form in modal
   - Formie forms
*****************************************************************/
@use '../01-abstracts/mixins' as *;



// Base styles
// ------------------------------------------------------------
fieldset {
   border: 0;
   margin: 0;
   padding: 0;

   // Hide visually
   legend {
      position: absolute;
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      white-space: nowrap;
      width: 1px;
   }
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="tel"],
select,
textarea {
   background-color: white;
   border: 1px solid var(--gray);
   font-size: 1rem;
   padding: 0.5rem 0.75rem;
   width: 100%;
}

label {
   color: var(--gray);
   font-family: var(--font-display);
   font-size: 0.75rem;
   font-weight: 700;
   text-transform: uppercase;
}

select {
   appearance: none;
   background-color: white;
   background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2851, 51, 51%29'></polygon></svg>");
   background-position: right 0.75rem center;
   background-repeat: no-repeat;
   background-size: 9px 6px;
   color: #8a8a8a;
   margin: 0 0 1rem;
   padding-right: 1.5rem;
}


// Search form in modal
// ------------------------------------------------------------
#search-modal form {
   label {
      @include bp($sm) {
         display: grid;
         grid-template-columns: auto max-content;
      }

      input {
         margin-bottom: 0.25rem;

         @include bp($sm) {
            margin: 0;
         }
      }
   }
}


// Formie forms
// ------------------------------------------------------------
.fui-field-rows > .fui-row > .fui-field > .fui-input-container,
.fui-row > .fui-field:not(.multi-field-row) > .fui-field-container {
   margin-bottom: 1.25rem;

   select {
      margin-bottom: 0;
   }
}

.fui-row {
   @include bp($lg) {
      display: flex;
      gap: 1.5rem;

      .fui-field {
         width: 100%;
      }
   }
}

.fui-alert {
   margin-bottom: 2rem;

   &-error {
      background-color: #FDFAE9;
      border: 1px solid #DAB901;
      color: #716006;
      font-size: 0.875rem;
      font-weight: 700;
      padding: 0.5rem 1rem;
   }
}

.fui-error-message {
   color: var(--orange);
   font-size: 0.75rem;
   font-weight: 700;
}

input.fui-error,
textarea.fui-error {
   border-color: var(--orange);
}

.fui-instructions {
   color: var(--gray);
   font-size: 0.75rem;
   text-align: right;
}

.fui-alert.fui-alert-success {
   color: var(--dkgray);
}