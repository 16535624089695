/*****************************************************************
   CALLOUTS
*****************************************************************/



.callout {
   padding: 2rem 1.25rem;
   background: var(--offwhite);
   text-align: center;
   position: relative;
 
   button {
     position: absolute;
     top: 0px;
     right: 10px;
     color: var(--blue);
     cursor: pointer;
     font-weight: 700;
     font-size: 2rem;
     -moz-transition: all 0.2s;
     -webkit-transition: all 0.2s;
     -o-transition: all 0.2s;
     transition: all 0.2s;
 
     &:hover {
       color: var(--gray);
     }
 
   }
 
   p {
     margin: 0;
     font-weight: 700;
     color: var(--blue);
     text-transform: uppercase;
   }
 }
 
 .errors {
   margin: 0;
   list-style: none;
   background: var(--offwhite);
 
   li {
     padding: 10px 15px;
     margin-bottom: 10px;
     font-weight: 700;
     color: var(--blue);
     text-transform: uppercase;
   }
 
   &:last-child {
     margin-bottom: 2rem;
   }
 }